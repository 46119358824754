.sweet-loading {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #0000006e;
}

.attribute-row {
  padding: 2% 0 1% 0;
  // border-top: 1px solid #e4e7ea;
  border-bottom: 1px solid #daf5ff;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group label,
.label-weight {
  font-weight: 550;
}

.button-margin {
  margin: 0px 10px;
}

.disable-outline {
  outline: none !important;
}

.cp-dialog-input {
  margin: 10px auto;
}

.main-container {
  margin: 10px auto;
}

.action-icon {
  cursor: pointer;
  margin: 0 3px;
  font-size: 17px;
}

.action-icon:hover {
  color: red !important;
}

.active.action-icon {
  color: green !important;
}

.edit.action-icon {
  color: #2ba8d8;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: #1d1d1d;
  color: red !important;
  // background-color: red;
  // border-color: red !important;
}

.page-link:focus {
  box-shadow: none;
}

.table-cell-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.page-link {
  padding: 7px 12px;
  border-color: #1d1d1d !important;
  color: #1d1d1d !important;
}

.page-link:hover {
  background-color: #ffffff;
  color: red !important;
}

select.form-control:not([size]):not([multiple]) {
  height: 2.1rem;
}

.custom-search-input {
  height: 2.1rem;
}

.black-btn {
  height: 2.1rem;
  background: #1d1d1d !important;
  border-color: #000000 !important;
  font-size: 14px;
  color: #ffffff !important;
}

.black-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 59, 0.5);
}

.black-btn:hover {
  color: red !important;
  background: #1b1b1b;
}

.red-btn {
  height: 2.1rem;
  background: red;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.red-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 59, 0.5);
}

.red-btn:hover {
  color: #fff !important;
  background: #1b1b1b;
}

.sidebar {
  background: #1d1d1d;
}

.sidebar .nav-link:hover {
  background: #b5b5b51f;
}

.sidebar .nav-link.active {
  color: red;
  background: #101010;
}

.sidebar .nav-link .nav-icon {
  color: #ffffff;
}

.sidebar .nav-link.active .nav-icon {
  color: red;
}

.edit.action-icon {
  color: #566272;
}

.loading-img {
  filter: blur(1.5px);
}

input[type="file"] {
  display: none;
}

.custom-file-upload-btn {
  border: 1px solid #ccc;
  border-radius: 3px;
  display: inline-block;
  padding: 4px 8px;
  background-color: #faebd7 !important;
  color: black;
  font-size: 13px;
  cursor: pointer;
}

.custom-file-upload-btn:hover {
  text-decoration: none;
  border-color: antiquewhite;
  color: black;
}

.custom-file-upload-btn.csv-dialog-btn {
  margin: 0px 5px 5px 0;
}

.preview-img-container {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
  border: 1px solid #dddddd;
}

.preview-delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 13px;
  cursor: pointer;
  color: #ff0000;
}

.form-preview_img {
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 3px;
}

.form-group label {
  vertical-align: middle;
  margin-top: 2px;
}

.dialog-height > .modal-content > .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.dialog-details-div {
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
  margin: 10px 0;
}

.dialog-details-div-edited {
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
  margin: 10px 0;
  width: 283px;
}

.dialog-details-div-heading {
  font-weight: 500;
  margin: 0px;
  padding: 5px;
  display: inline-block;
}
.dialog-details-div-heading-edited {
  font-weight: 500;
  margin: 0px;
  padding: 5px;
  display: inline-block;
  font-size: 16px !important;
  color: #ff0000 !important;
}

.dialog-details-div-heading-right-align {
  font-weight: 300;
  margin: 0px;
  padding: 5px;
  float: right;
}

.dialog-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.dialog-details-table {
  width: 100%;
}

.dialog-details-table td {
  padding: 0 5px;
  word-break: break-word;
}

.dialog-details-table-first-cell {
  width: 35%;
  font-weight: 500;
}

.dialog-details-table-second-cell {
  width: 65%;
  font-weight: 300;
}

.dialog-details-ndf-p {
  font-size: 14px;
  font-weight: 400;
}

.csv-table-container {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.csv-table-container table {
  width: 100%;
}

.csv-table-container table td {
  padding: 10px;
  border: 1px solid #d4d4d49e;
}

.csv-table-container table tr:first-child {
  font-weight: 500;
}

.card-header-custom {
  margin: 0;
}
.cache-section {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
}
.cache-time-div {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.cache-time {
  font-weight: 700;
  flex: none;
}
.cache-time-div h5 {
  margin-bottom: 0px;
}
.button-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #566272;
}

.form-control:focus {
  box-shadow: none;
  border-color: #566272;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #566272 !important;
}

.rdtPicker td.rdtToday::before {
  border-bottom-color: #566272 !important;
}

.table-bordered th,
.table-bordered td {
  text-align: center;
}

.css-31r155 {
  border-color: #313841 !important;
  border-bottom-color: transparent !important;
}

.btn-link {
  color: #566272 !important;
}
.btn-link:hover {
  color: #626262 !important;
}

.oroom-logo-login {
  height: 120px;
  margin: 20px auto;
  width: 100%;
}

.profile-img {
  height: 150px;
  width: 150px;
  border: 1px solid gray;
  border-radius: 50%;
  // transform: rotate(0deg);
}
.form-control[readonly] {
  background-color: #fff;
  // background-clip: padding-box;
}
.post-image {
  width: 75px;
  height: 75px;
}

.file-input {
  width: 200px;
}

.ck-editor__editable {
  min-height: 500px !important;
  max-height: 500px !important;
}

.vehicle-details-file-list {
  // padding: 0;
  list-style-type: none;
}

// .vehicle-details-file-list {
//   padding: 0;
//   list-style-type: none;
// }

.uploadData {
  bottom: 4px;
  right: 8px;
  position: relative;
}

.main-header {
  margin: 0;
  color: red;
  padding: 13px 61px;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.editIcon12 {
  width: 13px;
  position: relative;
  bottom: 3px;
  left: 0px;
}

.padding9890 {
  position: relative;
  //left: 56px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.editdata {
  position: relative;
  left: 21px;
}

.editedon {
  position: relative;
  left: 8px;
}

.csv_btn a:hover {
  text-decoration: none !important;
  color: #e20d17 !important;
}

.cus_vei_modal .modal-footer {
  justify-content: right !important;
}

.block_cursor {
  cursor: not-allowed;
}

.move_cursor {
  cursor: move;
}

.app-footer {
  justify-content: center;
}

.fa-sort {
  cursor: pointer !important;
}

.cus_header_new {
  justify-content: center;
  /* align-items: center; */
  display: flex;
  font-size: 17px;
}
